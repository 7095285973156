<template>
  <v-form ref="form" lazy-validation>

    <div class="content-containerStep2">
      <v-row class="justify-center align-center">
        <!-- Linke Spalte -->
        <v-col cols="12" lg="7" md="7" sm="12" xs="12" class="pa-4">
          <v-row>
            <v-col cols="12" class="mb-4 mt-4">
              <h1 class="text-center primary--text">Vielen Dank für Ihr Vertrauen!</h1>
            </v-col>
          </v-row>

          <v-row class="emmy-section">
            <v-col cols="12" lg="3" md="3" sm="12" xs="12">
              <img src="static/images/Emmy.jpg" alt="Mitarbeiterin Bild" class="responsive-image">
            </v-col>
            <v-col cols="12" lg="9" md="9" sm="12" xs="12" class="emmy-text-container">
              <div class="emmy-text">
                <p><strong>Vielen Dank, {{ patientAddress.lastName }}</strong>! Ihre Kontaktdaten sind nun
                  vervollständigt. Ich mache mich gerade daran, Ihren Antrag zu bearbeiten. <strong>Für die Koordination
                    mit Ihrer Pflegekasse</strong> benötige ich noch einige weitere Details von Ihnen.</p>
              </div>

            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" lg="6" md="6" sm="12" xs="12">
              <v-text-field v-model="customer.patientAddress.street" :rules="rules.streetRules"
                            label="Straße & Hausnummer" prepend-icon="mdi-home"/>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12">
              <v-text-field v-model="customer.patientAddress.zipCode" :rules="rules.zipRules"
                            label="Postleitzahl" prepend-icon="mdi-postage-stamp"/>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12">
              <v-text-field v-model="customer.patientAddress.city" :rules="rules.cityRules"
                            label="Ort" prepend-icon="mdi-city"/>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12">
              <p-b-datepicker :default-date="customer.birthdate" label="Geburtstag"
                              :rules="rules.birthdateRules" :max="now"
                              ref="datePicker"
                              v-on:change="customer.birthdate = $event"/>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12">
              <v-autocomplete :items="insurances" v-model="customer.insuranceId"
                              item-text="name" item-value="id" :rules="rules.insuranceRules"
                              label="Krankenkasse" prepend-icon="mdi-hospital"
                              no-data-text="Wir haben keine Krankenkasse mit diesem Namen gefunden."/>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12" xs="12">
              <v-text-field v-model="customer.insuranceNumber" :rules="rules.insuranceNumberRules"
                            label="Versichertennummer" prepend-icon="mdi-card-account-details"/>
            </v-col>

          </v-row>

          <v-col cols="12">
            <v-card rounded>
              <v-card-text class="pb-8">
                <v-checkbox :rules="rules.notempty" v-model="isMoveToUs"
                            label="Sollte bereits ein Vertrag zur Lieferung bestehen, so beauftrage ich MedicalPro24 GmbH mit der Kündigung des Vertrages meines bisherigen Anbieters mit dem heutigen Datum."/>

                <v-checkbox
                    :rules="rules.dataProtection"
                    v-model="agreeToPrivacyPolicy"
                    value="success"
                    hide-details
                    class="mr-2">
                  <template v-slot:label>
                 <span>
                  Ich habe die
                  <a @click.stop href="https://pflegehilfsmitel24.com/datenschutz/" target="_blank"
                     rel="noopener"
                     class="privacy-link primary--text">Datenschutzerklärung</a>
                  gelesen und bin mit dieser einverstanden.
                </span>
                  </template>
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-col>

          <v-row class="mt-4 mb-4">
            <v-col cols="12" class="d-flex justify-space-between align-center"> <!-- Flexbox-Utility-Klassen -->
              <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="goToLastStep()" text outlined x-large>
                Zurück
              </v-btn>

              <v-btn v-if="$vuetify.breakpoint.mdAndUp" color="gradient-primary" class="text-h5 white--text" x-large @click="openDialog()">
                Weiter
                <v-icon right class="ml-2" size="large">mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn v-if="$vuetify.breakpoint.mdAndDown" color="gradient-primary" class="text-h5 white--text" x-large block @click="openDialog()">
                Weiter
              </v-btn>

            </v-col>
          </v-row>


        </v-col>

        <!-- Rechte Spalte -->
        <v-col cols="0" md="5" lg="5" class="d-none d-md-block">
          <img
              src="static/images/Paketauspacken.webp"
              alt="Rechtes Bild"
              class="responsive-image-right"
          />
        </v-col>
      </v-row>
      <p-b-snackbar :msg="snackbarMessage"></p-b-snackbar>
    </div>

    <v-dialog v-model="forwardDialog" width="500" persistent>
      <v-card>
        <v-btn fab right absolute text @click="forwardDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="headline">
          Unterschrift für Kostenübernahme
        </v-card-title>

        <v-card-text>
          Bitte leisten Sie Ihre Unterschrift zur Bestätigung der Übernahme der Pflegekosten durch Ihre Pflegekasse.
          Durch Ihre Unterschrift bestätigen Sie, dass Sie die Bedingungen der Kostenübernahme verstanden haben und akzeptieren.
        </v-card-text>

        <v-card-text>
          <p-b-signature class="pa-3"></p-b-signature>
        </v-card-text>

        <v-card-text>
          <v-row>
            <v-col>
              <v-btn v-if="$vuetify.breakpoint.smAndDown"
                     @click="goToNextStep()"
                     :disabled="!$store.state.customer.signature"
                     :loading="isLoading"
                     color="background"
                     class="text-h6 white--text"
                     block x-large
              >
                Abschließen
              </v-btn>
              <v-btn v-else :loading="isLoading" :disabled="!$store.state.customer.signature" color="background" class="text-h5 white--text" @click="goToNextStep()" block x-large>
                Abschließen
              </v-btn>
            </v-col>
            <v-col>
              <p-b-share/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import {mapMutations} from 'vuex';
import PBStepper from "@/components/PB-Stepper.vue";
import PBDatepicker from "@/components/PB-Datepicker.vue";
import moment from "moment";
import PBSnackbar from "@/components/PBSnackbar.vue";
import {mapFields} from 'vuex-map-fields';
import {defineComponent} from "vue";
import PBSignature from "@/components/PB-Signature.vue";
import {useOnline} from "@vueuse/core";
import PBShare from "@/components/PB-Share.vue";

export default defineComponent({
  name: 'Step2Component',
  components: {PBSignature, PBSnackbar, PBDatepicker, PBStepper, PBShare},
  data() {
    return {
      agreeToPrivacyPolicy: false,
      differentDelivery: false,
      showModal: false,
      showSnackbar: false,
      snackbarMessage: {},
      menu: false,
      now: moment().format("YYYY-MM-DD"),
      isMoveToUs: "",
      forwardDialog: false,
      isLoading: false,

      rules: {
        sirRules: [(v) => !!v || ''],
        streetRules: [(v) => !!v || 'Bitte geben Sie Ihre Anschrift an.'],
        birthdateRules: [(v) => !!v || 'Bitte geben Sie Ihr Geburtsdatum an.'],
        zipRules: [(v) => !!v || 'Bitte geben Sie eine Postleitzahl an.'],
        cityRules: [(v) => !!v || 'Bitte geben Sie eine Stadt an.'],
        careDegreerules: [(v) => !!v || 'Bitte geben Sie einen Pflegegrad an.'],
        insuranceRules: [(v) => !!v || 'Bitte wählen Sie eine Krankenkasse aus.'],
        insuranceNumberRules: [
          (v) => !!v || 'Bitte geben Sie eine Versichertennummer an.',
          (v) => /^[A-Z]\d{9}$/.test(v) || 'Die Versichertennummer muss mit einem Großbuchstaben beginnen und von 9 Zahlen gefolgt sein.'
        ],
        nameRules: [(v) => !!v || 'Bitte geben Sie Ihren Namen an.'],
        prefixPhoneRules: [(v) => !!v || 'Bitte geben Sie Ihre vorwahl an.'],
        phoneRules: [(v) => !!v || 'Bitte geben Sie Ihre Telefonnummer an.'],
        dataProtection: [v => !!v || 'Bitte bestätigen Sie die Datenschutzerklärung.'],
        notempty: [v => !!v || 'Bitte füllen Sie alle Pflichtfelder aus.'],
        emailRules: [
          (v) => !!v || 'E-Mail ist erforderlich',
          (v) => /.+@.+\..+/.test(v) || 'E-Mail muss gültig sein',
          // Hier können Sie die E-Mail-Domains hinzufügen, die Sie blockieren möchten
          (v) => !/(fake\.com|spam\.de|example\.org)$/.test(v) || 'Diese E-Mail-Domain ist nicht erlaubt'
        ],
      }
    };
  },

  mounted() {
    if (!this.customer.firstDelivery) this.customer.firstDelivery = moment(moment.now()).format("YYYY-MM-DD").toString()
    if (!this.customer.deliveryInterval) this.customer.deliveryInterval = this.customer.firstDelivery?.match('(\\d{1,2})(?=\\D*$)')[0]
  },

  computed: {
    ...mapFields([
      'customer',
      'customer.collectiveAddressId',
      'customer.patientAddress',
      'customer.deliveryAddress',
      'customer.signature',
      'additionalInformation',
      'additionalInformation.consultantNumber',
      'system.insurances',
      'system.selectedProducts',
      'system.selectedBox',
      'system.products',
      'system.countryCodes',
      'system.requestChain'
    ]),

    isOnline() {
      return useOnline()?.value
    }
  },

  methods: {
    ...mapMutations(["increase", 'resetStore']),

    onCheckboxChange() {
      this.increase('WB')
      this.snackbarMessage = {
        text: 'Wir haben dreimal den wiederverwendbaren Bettschutz zu Ihrem Warenkorb hinzugefügt.',
        type: 'success'
      };
    },

    async goToLastStep() {
      this.$router.push('/lieferintervall').then();
      this.$vuetify.goTo(0).then()
    },

    async validateForm(){
      const isValid = await this.$refs.form.validate();

      if (!await this.$refs.datePicker?.validate()) {
        this.snackbarMessage = {text: 'Bitte füllen Sie alle Pflichtfelder aus.', type: 'warning'};
        return false;
      }

      if (!isValid) {
        this.snackbarMessage = {text: 'Bitte füllen Sie alle Pflichtfelder aus.', type: 'warning'};
        return false;
      }

      if (!this.customer.moveToUsDate) {
        this.customer.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD");
      }

      return true
    },

    async openDialog(){
      if(await this.validateForm()){
        this.forwardDialog = true
      }
    },

    async goToNextStep() {
      if(!await this.validateForm()){
        return;
      }

      if (!this.$store.state.customer.signature) {
        this.snackbarMessage = {text: 'Wir Benötigen ihre Unterschrift.', type: 'warning'};
        return;
      }

      const tmpDI = this.customer.deliveryInterval;

      if (typeof tmpDI === 'string') {
        // Entfernen führender Nullen bei numerischen Werten, die keine Brüche sind
        if (!isNaN(Number(tmpDI)) && !tmpDI.includes('/')) {
          this.customer.deliveryInterval = Number(tmpDI);
        }
        // Behalten von Bruchwerten als String
        else if (tmpDI.includes('/')) {
          const parts = tmpDI.split('/');
          if (parts.length === 2 && !isNaN(Number(parts[0])) && !isNaN(Number(parts[1])) && Number(parts[1]) !== 0) {
            // Keine Umwandlung in eine Zahl, der Bruch wird als String beibehalten
            this.customer.deliveryInterval = tmpDI;
          } else {
            console.error('Ungültiger Bruchwert');
          }
        }
      }


      if (!this.customer.moveToUsDate) {
        this.customer.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD");
      }

      const output = {
        customer: this.customer,
        additionalInformation: this.additionalInformation,
        selectedProducts: this.selectedProducts,
        selectedBox: this.selectedBox,
      };

      await this.checkOnlineAndSend(output);
    },

    async sendPostRequest(output) {
      const url = 'https://www.docdorado.de/register';
      try {
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(output),
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(response => response.text())
            .then((rawBody) => {
              if (rawBody.includes("CareBox already exists.")) {
                this.$router.push({path: '/fehler?error=bereitsAngelegt'}).then()
              } else {
                this.resetStore()
                this.$router.push({path: '/vielendank'}).then()
              }

            }).catch(e => {
          console.log(e)
        }).finally(() => {
          this.isLoading = false;
        })

      } catch (e) {
        console.log("Error: ", e);
        this.isLoading = false;
      }
    },

    checkOnlineAndSend(output) {
      this.isLoading = true;
      if (this.isOnline) {
        console.log("sending...")
        this.sendPostRequest(output);
      } else {
        this.requestChain.push(output)
        this.$router.push({path: '/offline'}).then(() => this.resetStore());
      }
    },
  },
  watch: {
    isMoveToUs() {
      if (this.isMoveToUs) {
        this.moveToUsDate = moment(moment.now()).format("YYYY-MM-DD")
      }
    }
  }
});
</script>


<style>

.content-containerStep2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh; /* Nehmen Sie die volle Höhe des Viewports */
  padding: 0 20px; /* Einige zusätzliche Polsterung an den Seiten, falls gewünscht */
}

.responsive-image {
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.responsive-image-right {
  max-width: 100%;
  height: auto;
}

.emmy-section {
  display: flex;
  align-items: center; /* Ausrichtung der Elemente in der Mitte */
}

.emmy-text-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Vertikale Zentrierung des Textes */
}

.emmy-text {
  font-size: 1.2em;
}

.gradient-primary {
  background: linear-gradient(315deg, #17aa9b, #17aa9b 80%);
  color: white; /* Setzen Sie eine Farbe für den Text, die gut mit dem Gradienten harmoniert */
}
</style>