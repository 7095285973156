<template>
  <div class="impressum">
    <h1>Impressum</h1>
    <p>Medicalpro24 GmbH</p>
    <p>Emscherhüttenstr. 27</p>
    <p>47119 Duisburg</p>
    <p>IK-Nummer: 330509583</p>

    <p>E-Mail: <a href="mailto:info@medicalpro24.de">info@medicalpro24.de</a></p>
    <p>TEL: <a href="tel:+4920372835533">+49 203 72 83 55 33</a></p>


    <p>
      Plattform der EU-Kommission zur Online-Streitbeilegung:
      <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>
    </p>

    <p>
      Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle nicht verpflichtet, hierzu jedoch bereit.
    </p>
  </div>
</template>

<script>
export default {
  name: "impressum"
}
</script>

<style scoped>
.impressum {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  max-width: 80%;
  margin: auto;
  padding: 20px;
}
.impressum h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
.impressum a {
  color: #007bff;
  text-decoration: none;
}
.impressum a:hover {
  text-decoration: underline;
}
</style>
