<template xmlns="http://www.w3.org/1999/html">
  <div class="datenschutz-container">
    <h1>Datenschutzerklärung</h1>
    <p>Soweit nachstehend keine anderen Angaben gemacht werden, ist die Bereitstellung Ihrer
      personenbezogenen Daten weder gesetzlich oder vertraglich vorgeschrieben, noch für einen
      Vertragsabschluss erforderlich. Sie sind zur Bereitstellung der Daten nicht verpflichtet. Eine
      Nichtbereitstellung hat keine Folgen. Dies gilt nur soweit bei den nachfolgenden
      Verarbeitungsvorgängen keine anderweitige Angabe gemacht wird.
      “Personenbezogene Daten” sind alle Informationen, die sich auf eine identifizierte oder
      identifizierbare natürliche Person beziehen.</p>

    <h2>Server-Logfiles</h2><br>
    <p>Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen.
      Bei jedem Zugriff auf unsere Website werden an uns oder unseren Webhoster / IT-Dienstleister
      Nutzungsdaten durch Ihren Internet Browser übermittelt und in Protokolldaten (sog. ServerLogfiles) gespeichert. Zu diesen gespeicherten Daten gehören z.B. der Name der aufgerufenen
      Seite, Datum und Uhrzeit des Abrufs, die IP-Adresse, die übertragene Datenmenge und der
      anfragende Provider. Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus
      unserem überwiegenden berechtigten Interesse an der Gewährleistung eines störungsfreien
      Betriebs unserer Website sowie zur Verbesserung unseres Angebotes</p>

    <h2>Bestellungen</h2><br>
    <p>Erhebung, Verarbeitung und Weitergabe personenbezogener Daten bei Bestellungen
      Bei der Bestellung erheben und verarbeiten wir Ihre personenbezogenen Daten nur, soweit dies zur
      Erfüllung und Abwicklung Ihrer Bestellung sowie zur Bearbeitung Ihrer Anfragen erforderlich ist.
      Die Bereitstellung der Daten ist für den Vertragsschluss erforderlich. Eine Nichtbereitstellung hat
      zur Folge, dass kein Vertrag geschlossen werden kann. Die Verarbeitung erfolgt auf Grundlage des
      Art. 6 Abs. 1 lit. b DSGVO und ist für die Erfüllung eines Vertrags mit Ihnen erforderlich.
      Eine Weitergabe Ihrer Daten erfolgt dabei beispielsweise an die von Ihnen gewählten
      Versandunternehmen und Dropshipping Anbieter, Zahlungsdienstleister, Diensteanbieter für die
      Bestellabwicklung und IT-Dienstleister. In allen Fällen beachten wir strikt die gesetzlichen
      Vorgaben. Der Umfang der Datenübermittlung beschränkt sich auf ein Mindestmaß.
      Gibt der Kunde sein Einverständnis für die Unterbreitung weiterer Angebote in schriftlicher,
      fernmündlicher (Telefon) oder elektronischer Form, so ist dies freiwillig , und hat keinen Einfluss
      auf die Wirksamkeit des weiteren Vertrages.
      Die erforderlichen Daten für die für die Unterbreitung weiterer Angebote werden entsprechend den
      gesetzlichen Vorschriften gespeichert, und im erforderlichen Maß an von uns beauftragte
      Dienstleister weitergegeben.</p>

    <h2>Datenerhebung</h2><br>
    <p>Verantwortlich für die Daten:<br>
      MedicalPro24 GmbH<br>
      Emscherhüttenstraße 27<br>
      47119 Duisburg,<br>
      Fon: 0203 72835533,<br>
      E-Mail: info@Pflegehilfsmittel24.shop<br>
      Datenschutzbeauftragter:<br>
      Die Kontaktdaten des für die Datenverarbeitung Verantwortlichen finden Sie in unserem<br>
      Impressum.<br>
      Auf welcher Rechtsgrundlage und wofür verarbeiten wir Ihre Daten:
      Die personenbezogenen Daten werden unter den Bestimmungen der Europäischen
      Datenschutzgrundverordnung (DSGVO) und dem Bundesdatenschutzgesetz (BDSG) erhoben.<br><br>
      1. Für die Erfüllung vertraglicher Verpflichtungen (Art. 6 Abs. 1 b DSGVO)
      Um die Leistungen im Rahmen der Auftrags- und Vertragsabwicklung über einen werbenden
      Kunden an einen abweichenden Empfänger zu erbringen werden die erhobenen Daten benötigt. Die
      Angabe der Bankverbindung (Kreditinstitut und IBAN) ist für die Auszahlung der Prämie des
      Werbers notwendig. In erster Linie richten sich die Zwecke der Datenverarbeitung nach den
      konkret beauftragten Leistungen. Sie können weitere Details zu den Datenverarbeitungszwecken
      den Allgemeinen Geschäftsbedingungen entnehmen.<br><br>
      2. Auf Basis einer Einwilligung (Art. 6 Abs. 1 a DSGVO)
      Ist eine Einwilligung zur Verarbeitung von personenbezogenen Daten für bestimmte Zwecke (z. B.
      für die Weitergabe von Daten an Kooperationspartner) erteilt worden, ist auf Basis dieser
      Einwilligung die Rechtmäßigkeit der Verarbeitung vorhanden. Die Einwilligung kann jederzeit mit
      sofortiger Wirkung widerrufen werden.<br><br>
      3. Auf Basis gesetzlicher Vorgaben (Art. 6 Abs. 1 c DSGVO) oder im öffentlichen Interesse (Art. 6
      Abs. 1 e DSGVO)
      Die Verarbeitung erfolgt nach gesetzlichen Vorgaben, u.a. wegen Kontroll- und Meldepflichten
      oder zur Erfüllung handelsrechtlicher und steuerrechtlicher Vorgaben.
      Empfänger oder Kategorien von Empfängern, denen die Daten mitgeteilt werden können
      Zur Erfüllung unserer vertraglichen und gesetzlichen Verpflichtungen können Kundendaten an
      externe Dienstleister weitergegeben werden:<br>
      – Kundenbetreuungsunternehmen (z.B. Callcenter)<br>
      – Geldinstitute<br>
      – Logistik/Versanddienstleister<br>
      – Abrechnungsdienstleister<br>
      Dauer der Datenspeicherung<br><br>
      Die Daten werden so lange gespeichert, wie dies zur Abwicklung der Bestellvorgänge erforderlich
      ist bzw. ein berechtigtes Interesse an der weiteren Speicherung vorliegt. Insbesondere im Rahmen
      von Bestellungen gelten für uns die Anforderungen des 257 HGB von 6, bzw. 10 Jahren für die
      Aufbewahrung von Unterlagen. Bei allen anderen Fällen löschen wir alle persönlichen Daten.
      Rechte als betroffene Person
      Sie können folgende Rechte als betroffene Person ausüben:<br><br>
      – Auskunft bezüglich Ihrer persönlichen gespeicherten Daten<br>
      – Löschung, Korrektur oder Einschränkung der Verarbeitung Ihrer Daten<br>
      – Widerspruch gegen die Verarbeitung Ihrer Daten<br>
      – die Übertragung der von Ihnen bereitgestellten Daten<br>
      – Sie haben das Recht, diese jederzeit zu widerrufen, sollte die Verarbeitung Ihrer Daten auf einer
      Einwilligung beruhen<br>
      Beschwerderechte<br>
      Sie haben das Recht auf eine Beschwerde bei der zuständigen Datenschutzaufsichtsbehörde:<br><br>
      Die Landesbeauftragte für den Datenschutz NRW<br>
      Bettina Gayk<br>
      +49 (0)211 / 38424 – 0<br>
      poststelle@ldi.nrw.de<br>
    </p>

    <h2>Kontakt</h2><br>
    <p>Verantwortlicher
      Kontaktieren Sie uns auf Wunsch. Die Kontaktdaten des für die Datenverarbeitung
      Verantwortlichen finden Sie in unserem Impressum.
      Initiativ-Kontaktaufnahme des Kunden per E-Mail Wenn Sie per E-Mail initiativ mit uns in
      Geschäftskontakt treten, erheben wir Ihre personenbezogenen Daten (Name, E-Mail-Adresse,
      Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die Datenverarbeitung
      dient der Bearbeitung und Beantwortung Ihrer Kontaktanfrage.
      Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei
      Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns
      geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit.
      b DSGVO.
      Erfolgt die Kontaktaufnahme aus anderen Gründen erfolgt diese Datenverarbeitung auf Grundlage
      des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der
      Bearbeitung und Beantwortung Ihrer Anfrage. In diesem Fall haben Sie das Recht, aus Gründen, die
      sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO
      beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
      Ihre E-Mail-Adresse nutzen wir in diesem Fall nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden
      anschließend unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der
      weitergehenden Verarbeitung und Nutzung nicht zugestimmt haben.
      Erhebung und Verarbeitung bei Nutzung des Kontaktformulars
      Bei der Nutzung des Kontaktformulars erheben wir Ihre personenbezogenen Daten (Name, E-MailAdresse, Nachrichtentext) nur in dem von Ihnen zur Verfügung gestellten Umfang. Die
      Datenverarbeitung dient dem Zweck der Kontaktaufnahme.
      Wenn die Kontaktaufnahme der Durchführung vorvertraglichen Maßnahmen (bspw. Beratung bei
      Kaufinteresse, Angebotserstellung) dient oder einen bereits zwischen Ihnen und uns
      geschlossenen Vertrag betrifft, erfolgt diese Datenverarbeitung auf Grundlage des Art. 6 Abs. 1 lit.
      b DSGVO.
      Erfolgt die Kontaktaufnahme aus anderen Gründen erfolgt diese Datenverarbeitung auf Grundlage
      des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der
      Bearbeitung und Beantwortung Ihrer Anfrage. In diesem Fall haben Sie das Recht, aus Gründen, die
      sich aus Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO
      beruhenden Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
      Ihre E-Mail-Adresse nutzen wir nur zur Bearbeitung Ihrer Anfrage. Ihre Daten werden anschließend
      unter Beachtung gesetzlicher Aufbewahrungsfristen gelöscht, sofern Sie der weitergehenden
      Verarbeitung und Nutzung nicht zugestimmt haben</p>

    <h2>Newsletter</h2><br>
    <p>Falls Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen
      eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der
      Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
      einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese
      Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben
      diese nicht an Dritte weiter.
      Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich
      auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
      Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters
      können Sie jederzeit widerrufen, etwa über den “Austragen”-Link im Newsletter. Die
      Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
      Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
      zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters
      gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für
      den Mitgliederbereich) bleiben hiervon unberührt.
      Kategorien von Empfängern der personenbezogenen Daten
      Wir arbeiten im Rahmen der Vermittlung verschiedener Dienstleistungen und Produkte, mit
      unterschiedlichen Partnern zusammen. Da wir immer den für Sie optimalen Partner auswählen,
      nachdem wir alle erforderlichen Informationen vorliegen haben, teilen wir Ihnen diesen im
      Nachgang an Ihre Anfrage individuell per E-Mail mit.
      Im Anschluss werden Sie von unseren Partnern kontaktiert. Der Partner betreut Sie in eigener
      Verantwortung. Um eine optimale Kundenbetreuung zu gewährleisten, teilen wir mit dem Händler
      im Rahmen der Zusammenarbeit die hierfür erforderlichen von Ihnen über unser Formular
      mitgeteilten Daten.
      Käufersiegel-Kundenbewertung
      Wir verwenden auf unserer Website das Käufersiegel-Kundenbewertungs-Tool der Händlerbund
      Management AG (Torgauer Straße 233 B, 04347 Leipzig). Nach Ihrer Bestellung möchten wir Sie
      bitten, Ihren Kauf bei uns zu bewerten und zu kommentieren.
      Zu diesem Zweck werden Sie von uns per E-Mail angeschrieben, wobei wir uns hierbei des
      technischen Systems des Anbieters des Käufersiegel-Bewertungstools im Rahmen einer
      Auftragsverarbeitung bedienen.
      Dabei erfolgt die Verarbeitung Ihrer Daten entweder mit Ihrer Einwilligung oder aufgrund unseres
      berechtigten Interesses.
      Die Verarbeitung erfolgt auf Grundlage des Art. 6 Abs.1 lit. a DSGVO mit Ihrer Einwilligung, sofern
      Sie dem Erhalt der Bewertungsaufforderung ausdrücklich zugestimmt haben. Sie können die
      Einwilligung jederzeit unter Nutzung des entsprechenden Links in der E-Mail widerrufen, ohne dass
      die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt
      wird. Ihre E-Mail-Adresse wird danach aus dem Verteiler entfernt.
      Die Verarbeitung ohne ausdrückliche Einwilligung erfolgt auf Grundlage des Art. 6 Abs.1 lit. f
      DSGVO aus dem berechtigten Interesse an wahrheitsgemäßen, verifizierten Bewertungen unserer
      Leistungen im Rahmen der Direktwerbung. Zu diesem Zweck versenden wir in elektronischer Form
      eine Bewertungsaufforderung für eigene Waren oder Dienstleistungen, die Sie bereits bei uns
      erworben haben. Die Versendung erfolgt an die E-Mail-Adresse, die wir im Rahmen des Verkaufes
      einer Ware oder Dienstleistung von Ihnen erhalten haben. Die Versendung der
      Bewertungsaufforderung steht unter dem Vorbehalt, dass Sie dieser Verwendung Ihrer E-MailAdresse nicht widersprochen haben.
      Der Widerspruch ist jederzeit durch Mitteilung an uns möglich. Die Kontaktdaten für die Ausübung
      des Widerspruchs finden Sie im Impressum. Sie können auch den dafür vorgesehenen Link in der
      Bewertungsaufforderung nutzen. Hierfür entstehen keine anderen als die Übermittlungskosten
      nach den Basistarifen.
      Die in diesem Zusammenhang im technischen System des Käufersiegel-Bewertungstools
      gespeicherten personenbezogenen Daten werden 3 Monate nach der zur Bewertung erfassten
      Warenlieferung gelöscht.</p>

    <h2>Versanddienstleister</h2><br>
    <p>Weitergabe der E-Mail-Adresse an Versandunternehmen zur Information über den Versandstatus.
      Wir geben Ihre E-Mail-Adresse im Rahmen der Vertragsabwicklung an das Transportunternehmen
      weiter, sofern Sie dem ausdrücklich im Bestellvorgang zugestimmt haben. Die Weitergabe dient
      dem Zweck, Sie per E-Mail über den Versandstatus zu informieren. Die Verarbeitung erfolgt auf
      Grundlage des Art. 6 Abs. 1 lit. a DSGVO mit Ihrer Einwilligung. Sie können die Einwilligung jederzeit
      durch Mitteilung an uns oder das Transportunternehmen widerrufen, ohne dass die Rechtmäßigkeit
      der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird</p>

    <h2>Cookies</h2><br>
    <p>Unsere Website verwendet Cookies. Cookies sind kleine Textdateien, die im Internetbrowser bzw.
      vom Internetbrowser auf dem Computersystem eines Nutzers gespeichert werden. Ruft ein Nutzer
      eine Website auf, so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden.
      Dieser Cookie enthält eine charakteristische Zeichenfolge, die eine eindeutige Identifizierung des
      Browsers beim erneuten Aufrufen der Website ermöglicht.
      Cookies werden auf Ihrem Rechner gespeichert. Daher haben Sie die volle Kontrolle über die
      Verwendung von Cookies. Durch die Auswahl entsprechender technischer Einstellungen in Ihrem
      Internetbrowser können Sie vor dem Setzen von Cookies benachrichtigt werden und über die
      Annahme einzeln entscheiden sowie die Speicherung der Cookies und Übermittlung der
      enthaltenen Daten verhindern. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Wir
      weisen Sie jedoch darauf hin, dass Sie dann gegebenenfalls nicht sämtliche Funktionen dieser
      Website vollumfänglich werden nutzen können.
      Unter den nachstehenden Links können Sie sich informieren, wie Sie die Cookies bei den
      wichtigsten Browsern verwalten (u.a. auch deaktivieren) können:
      Chrome: https://support.google.com/accounts/answer/61416?hl=de
      Internet Explorer: https://support.microsoft.com/de-de/help/17442/windows-internet-explorerdelete-manage-cookies
      Mozilla Firefox: https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen
      Safari: https://support.apple.com/de-de/guide/safari/manage-cookies-and-website-data-sfri11471/
      mac
      Technisch notwendige Cookies
      Soweit nachstehend in der Datenschutzerklärung keine anderen Angaben gemacht werden setzen
      wir nur diese technisch notwendigen Cookies zu dem Zweck ein, unser Angebot
      nutzerfreundlicher, effektiver und sicherer zu machen. Des Weiteren ermöglichen Cookies unseren
      Systemen, Ihren Browser auch nach einem Seitenwechsel zu erkennen und Ihnen Services
      anzubieten. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht
      angeboten werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel
      wiedererkannt wird.
      Die Verarbeitung Ihrer personenbezogenen Daten erfolgt in diesem Fall auf Grundlage des Art. 6
      Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung der
      optimalen Funktionalität der Website sowie einer nutzerfreundlichen und effektiven Gestaltung
      unseres Angebots.
      Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
      dieser Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
      Verwendung von Cookiebot<br>
      Wir verwenden auf unserer Website das Consent-Management-Tool Cookiebot der Cybot A/S,
      Havnegade 39, 1058 Kopenhagen, Dänemark; “Cookiebot”).
      Das Tool ermöglicht es Ihnen, Einwilligungen in Datenverarbeitungen über die Website,
      insbesondere das Setzen von Cookies, zu erteilen sowie von Ihrem Widerrufsrecht für bereits
      erteilte Einwilligungen Gebrauch zu machen. Die Datenverarbeitung dient dem Zweck, erforderliche
      Einwilligungen in Datenverarbeitungen einzuholen sowie zu dokumentieren und damit gesetzliche
      Verpflichtungen einzuhalten.<br>
      Hierzu können Cookies eingesetzt werden. Dabei können u. a. folgende Informationen erhoben und
      an Cookiebot übermittelt werden: anonymisierte IP-Adresse, Datum und Uhrzeit der Zustimmung,
      URL, von der die Zustimmung gesendet wurde, anonymer, zufälliger, verschlüsselter Key,
      Einwilligungsstatus. Eine Weitergabe dieser Daten an sonstige Dritte erfolgt nicht.
      Die Verarbeitung Ihrer personenbezogenen Daten erfolgt in diesem Fall auf Grundlage des Art. 6
      Abs. 1 lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an der Gewährleistung der
      optimalen Funktionalität der Website sowie einer nutzerfreundlichen und effektiven Gestaltung
      unseres Angebots</p>

    <h2>Analyse</h2><br>
    <p>Die in diesem Abschnitt nachfolgend dargestellten Datenverarbeitungen, insbesondere das Setzen
      von Cookies, erfolgen auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO aus unserem überwiegenden
      berechtigten Interesse:<br>
      – an der bedarfsgerechten und zielgerichteten Gestaltung der Website, bspw. bei Analyse- und
      Statistik-Tools<br>
      Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
      dieser auf Art. 6 Abs 1 lit. f DSGVO beruhenden Verarbeitungen Sie betreffender
      personenbezogener Daten zu widersprechen.
      Verwendung von Google Analytics<br>
      Wir verwenden auf unserer Website den Webanalysedienst Google Analytics der Google Inc. (1600
      Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“).<br>
      Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und ihrer Besucher. Dazu wird
      Google im Auftrag des Betreibers dieser Website die gewonnenen Informationen benutzen, um Ihre
      Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen
      und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
      gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem
      Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
      Google Analytics verwendet Cookies, die eine Analyse der Benutzung der Website durch Sie
      ermöglichen. Die durch die Cookies erzeugten Informationen über Ihre Benutzung dieser Website
      werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Auf
      dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google
      innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des
      Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
      die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
      Zwischen uns und Google bestehen aktuell Standardvertragsklauseln (SCCs.).
      Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website
      bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
      Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plug-in
      herunterladen und installieren [https://tools.google.com/dlpage/gaoptout?hl=de].
      Um die Datenerfassung und -speicherung durch Google Analytics geräteübergreifend zu
      verhindern, können Sie einen Opt-Out-Cookie setzen. Opt-Out-Cookies verhindern die zukünftige
      Erfassung Ihrer Daten beim Besuch dieser Website. Sie müssen das Opt-Out auf allen genutzten
      Systemen und Geräten durchführen, damit dies umfassend wirkt. Wenn Sie das Opt-out-Cookie
      löschen, werden wieder Anfragen an Google übermittelt. Wenn Sie hier klicken, wird das Opt-OutCookie gesetzt: Google Analytics deaktivieren.
      Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter https://
      www.google.com/analytics/terms/de.html bzw. unter https://www.google.de/intl/de/policies/.
      Google Ads<br>
      Unsere Website verwendet Google Ads. Google Ads ist ein Online-Werbeprogramm der Google
      Ireland Limited („Google“), Gordon House, Barrow Street, Dublin 4, Irland.
      Google Ads ermöglicht es uns Werbeanzeigen in der Google-Suchmaschine oder auf
      Drittwebseiten auszuspielen, wenn der Nutzer bestimmte Suchbegriffe bei Google eingibt
      (Keyword-Targeting). Ferner können zielgerichtete Werbeanzeigen anhand der bei Google
      vorhandenen Nutzerdaten (z. B. Standortdaten und Interessen) ausgespielt werden (ZielgruppenTargeting). Wir als Websitebetreiber können diese Daten quantitativ auswerten, indem wir
      beispielsweise analysieren, welche Suchbegriffe zur Ausspielung unserer Werbeanzeigen geführt
      haben und wie viele Anzeigen zu entsprechenden Klicks geführt haben.
      Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a
      DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
      Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
      gestützt. Details finden Sie hier: https://policies.google.com/privacy/frameworks und https://
      privacy.google.com/businesses/controllerterms/mccs/.
      Google Conversion-Tracking<br>
      Diese Website nutzt Google Conversion Tracking. Anbieter ist die Google Ireland Limited
      („Google“), Gordon House, Barrow Street, Dublin 4, Irland.<br>
      Mit Hilfe von Google-Conversion-Tracking können Google und wir erkennen, ob der Nutzer
      bestimmte Aktionen durchgeführt hat. So können wir beispielsweise auswerten, welche Buttons auf
      unserer Website wie häufig geklickt und welche Produkte besonders häufig angesehen oder
      gekauft wurden. Diese Informationen dienen dazu, Conversion-Statistiken zu erstellen. Wir
      erfahren die Gesamtanzahl der Nutzer, die auf unsere Anzeigen geklickt haben und welche
      Aktionen sie durchgeführt haben. Wir erhalten keine Informationen, mit denen wir den Nutzer
      persönlich identifizieren können. Google selbst nutzt zur Identifikation Cookies oder vergleichbare
      Wiedererkennungstechnologien.<br>
      Die Nutzung dieses Dienstes erfolgt auf Grundlage Ihrer Einwilligung nach Art. 6 Abs. 1 lit. a
      DSGVO und § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar.
      Mehr Informationen zu Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen
      von Google: https://policies.google.com/privacy?hl=de</p>

    <h2>Plug-ins und Sonstiges</h2><br>
    <p>Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
      datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre
      personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen
      darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert
      werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an
      Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen
      könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste)
      Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und
      dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss. Mit der
      Zustimmung der Nutzung der Cookies willigen sie in die Übertragung der Daten in die USA ein.
      Verwendung des Google Tag Managers<br>
      Wir verwenden auf unserer Website den Google Tag Manager der Google LLC. (1600 Amphitheatre
      Parkway, Mountain View, CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im
      Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited (Gordon House,
      Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche. Google Ireland
      Limited ist demnach das mit Google verbundene Unternehmen, welches für die Verarbeitung Ihrer
      Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich ist.
      Mit dieser Anwendung werden JavaScript-Tags und HTML-Tags verwaltet, die zur Implementierung
      insbesondere von Tracking- und Analyse-Tools verwendet werden. Die Datenverarbeitung dient
      dem Zweck der bedarfsgerechten Gestaltung und der Optimierung unserer Website.
      Der Google Tag Manager selbst speichert weder Cookies noch werden hierdurch
      personenbezogene Daten verarbeitet. Er ermöglicht jedoch die Auslösung weiterer Tags, die
      personenbezogene Daten erheben und verarbeiten können.<br>
      Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie <a href="https://marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy/">hier</a>.<br>
      <br>Verwendung von Google reCAPTCHA<br>
      Wir verwenden auf unserer Website den Dienst reCAPTCHA der Google LLC (1600 Amphitheatre
      Parkway, Mountain View, CA 94043, USA; „Google“).<br>
      Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz
      haben, ist Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten
      zuständige Verantwortliche. Google Ireland Limited ist demnach das mit Google verbundene
      Unternehmen, welches für die Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren
      Datenschutzgesetze verantwortlich ist. Die Abfrage dient dem Zweck der Unterscheidung der
      Eingabe durch einen Menschen oder durch automatisierte, maschinelle Verarbeitung. Dazu wird
      Ihre Eingabe an Google übermittelt und dort weiterverwendet. Zusätzlich werden die IP-Adresse
      und gegebenenfalls weitere von Google für den Dienst reCAPTCHA benötigte Daten an Google
      übertragen. Diese Daten werden von Google innerhalb der Europäischen Union verarbeitet und
      gegebenenfalls auch in die USA übermittelt. Für die USA ist kein Angemessenheitsbeschluss der
      EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von
      Standardvertragsklauseln als geeignete Garantien für den Schutz der personenbezogenen Daten,
      einsehbar unter: https://policies.google.com/privacy/frameworks.<br><br>
      Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f
      DSGVO aus unserem überwiegenden berechtigten Interesse unsere Website vor automatisierter
      Ausspähung, Missbrauch und SPAM zu schützen. Sie haben das Recht aus Gründen, die sich aus
      Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden
      Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
      Nähere Informationen zu Google reCAPTCHA sowie die dazugehörige Datenschutzerklärung finden
      Sie unter: https://www.google.com/recaptcha/intro/android.html sowie https://www.google.com/
      privacy.<br><br>
      Verwendung von Google invisible reCAPTCHA
      Wir verwenden auf unserer Website den Dienst invisible reCAPTCHA der Google LLC (1600
      Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Soweit Sie Ihren gewöhnlichen
      Aufenthalt im Europäischen Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited
      (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten zuständige Verantwortliche.
      Google Ireland Limited ist demnach das mit Google verbundene Unternehmen, welches für die
      Verarbeitung Ihrer Daten und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich
      ist.<br><br>
      Dies dient dem Zweck der Unterscheidung der Eingabe durch einen Menschen oder durch
      automatisierte, maschinelle Verarbeitung. Im Hintergrund sammelt und analysiert Google
      Nutzungsdaten, die von Invisible reCaptcha dazu verwendet werden, um reguläre Benutzer von
      Bots zu unterscheiden. Dazu wird Ihre Eingabe an Google übermittelt und dort weiterverwendet.
      Zusätzlich werden die IP-Adresse und gegebenenfalls weitere von Google für den Dienst Invisible
      reCAPTCHA benötigte Daten an Google übertragen.<br><br>
      Diese Daten werden von Google innerhalb der Europäischen Union und gegebenenfalls auch in die
      USA verarbeitet. Für die USA ist kein Angemessenheitsbeschluss der EU-Kommission vorhanden.
      Die Datenübermittlung erfolgt u.a auf Grundlage von Standardvertragsklauseln als geeignete
      Garantien für den Schutz der personenbezogenen Daten, einsehbar unter: https://
      policies.google.com/privacy/frameworks.<br><br>
      Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f
      DSGVO aus unserem überwiegenden berechtigten Interesse unsere Website vor automatisierter
      Ausspähung, Missbrauch und SPAM zu schützen. Sie haben das Recht aus Gründen, die sich aus
      Ihrer besonderen Situation ergeben, jederzeit dieser auf Art. 6 Abs. 1 lit. f DSGVO beruhenden
      Verarbeitungen Sie betreffender personenbezogener Daten zu widersprechen.
      Nähere Informationen zu Google reCAPTCHA sowie die dazugehörige Datenschutzerklärung finden
      Sie unter: https://www.google.com/recaptcha/intro/android.html sowie https://www.google.com/
      privacy<br><br>
      Verwendung von YouTube<br>
      Wir verwenden auf unserer Website die Funktion zur Einbettung von YouTube-Videos der Google
      Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irland; „YouTube“).YouTube ist ein mit der
      Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; “Google”) verbundenes
      Unternehmen.<br><br>
      Die Funktion zeigt bei YouTube hinterlegte Videos in einem iFrame auf der Website an. Dabei ist die
      Option „Erweiterter Datenschutzmodus“ aktiviert. Dadurch werden von YouTube keine
      Informationen über die Besucher der Website gespeichert. Erst wenn Sie sich ein Video ansehen,
      werden Informationen darüber an YouTube übermittelt und dort gespeichert. Ihre Daten werden
      gegebenenfalls in die USA übermittelt. Für die USA ist kein Angemessenheitsbeschluss der EUKommission vorhanden. Die Datenübermittlung erfolgt u.a auf Grundlage von
      Standardvertragsklauseln als geeignete Garantien für den Schutz der personenbezogenen Daten,
      einsehbar unter: https://policies.google.com/privacy/frameworks.<br><br>
      Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1 lit. f
      DSGVO aus unserem überwiegenden berechtigten Interesse an der bedarfsgerechten und
      zielgerichteten Gestaltung der Website. Sie haben das Recht aus Gründen, die sich aus Ihrer
      besonderen Situation ergeben, jederzeit dieser Verarbeitungen Sie betreffender
      personenbezogener Daten zu widersprechen.<br><br>
      Nähere Informationen zur Erhebung und Nutzung der Daten durch YouTube und Google, über Ihre
      diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den
      Datenschutzhinweisen von YouTube unter https://www.youtube.com/t/privacy.<br><br>
      Verwendung von Google Fonts
      Wir verwenden auf unserer Website Google Fonts der Google Ireland Limited (Gordon House,
      Barrow Street, Dublin 4, Irland; “Google”).<br><br>
      Die Datenverarbeitung dient dem Zweck der einheitlichen Darstellung von Schriftarten auf unserer
      Website. Um die Schriftarten zu laden, wird beim Seitenaufruf eine Verbindung zu Servern von
      Google hergestellt. Hierbei können Cookies eingesetzt werden. Dabei wird u.a. Ihre IP-Adresse
      sowie Informationen zu dem von Ihnen genutzten Browser verarbeitet und an Google übermittelt.
      Diese Daten werden nicht mit Ihrem Google-Konto verknüpft.<br><br>
      Ihre Daten werden gegebenenfalls in die USA übermittelt. Für die USA ist kein
      Angemessenheitsbeschluss der EU-Kommission vorhanden. Die Datenübermittlung erfolgt u.a auf
      Grundlage von Standardvertragsklauseln als geeignete Garantien für den Schutz der
      personenbezogenen Daten, einsehbar unter: https://policies.google.com/privacy/frameworks.
      Die Nutzung von Cookies oder vergleichbarer Technologien erfolgt auf Grundlage des § 15 Abs. 3
      S. 1 TMG. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt auf Grundlage des Art. 6 Abs. 1
      lit. f DSGVO aus unserem überwiegenden berechtigten Interesse an einer nutzerfreundlichen und
      ästhetischen Gestaltung unserer Website. Sie haben das Recht aus Gründen, die sich aus Ihrer
      besonderen Situation ergeben, jederzeit gegen diese auf Art. 6 Abs. 1 lit. f DSGVO beruhende
      Verarbeitung Sie betreffender personenbezogener Daten durch eine Mitteilung an uns zu
      widersprechen.<br><br>
      Nähere Informationen zur Datenverarbeitung und zum Datenschutz finden Sie unter https://
      www.google.de/intl/de/policies/ sowie unter https://developers.google.com/fonts/faq.<br><br>
      Verwendung von Facebook<br>
      Auf dieser Website sind Elemente des sozialen Netzwerks Facebook integriert. Anbieter dieses
      Dienstes ist die Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland. Die
      erfassten Daten werden nach Aussage von Facebook jedoch auch in die USA und in andere
      Drittländer übertragen.<br>
      Eine Übersicht über die Facebook Social-Media-Elemente finden Sie hier: https://
      developers.facebook.com/docs/plugins/?locale=de_DE.<br><br>
      Wenn das Social-Media-Element aktiv ist, wird eine direkte Verbindung zwischen Ihrem Endgerät
      und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer
      IP-Adresse diese Website besucht haben. Wenn Sie den Facebook „Like-Button“ anklicken,
      während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte dieser Website auf
      Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser Website Ihrem
      Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
      vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere
      Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter: https://dede.facebook.com/privacy/explanation.<br>
      Einsatz des o. g. Dienstes erfolgt auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 TTDSG.
      Die Einwilligung ist jederzeit widerrufbar.<br><br>
      Soweit keine Einwilligung eingeholt wurde, erfolgt die Verwendung des Dienstes auf Grundlage
      unseres berechtigten Interesses an einer möglichst umfassenden Sichtbarkeit in den Sozialen
      Medien.<br><br>
      Soweit mit Hilfe des hier beschriebenen Tools personenbezogene Daten auf unserer Website
      erfasst und an Facebook weitergeleitet werden, sind wir und die Meta Platforms Ireland Limited, 4
      Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese Datenverarbeitung
      verantwortlich (Art. 26 DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei
      ausschließlich auf die Erfassung der Daten und deren Weitergabe an Facebook. Die nach der
      Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht Teil der gemeinsamen
      Verantwortung. Die uns gemeinsam obliegenden Verpflichtungen wurden in einer Vereinbarung
      über gemeinsame Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden Sie
      unter: https://www.facebook.com/legal/controller_addendum. Laut dieser Vereinbarung sind wir für
      die Erteilung der Datenschutzinformationen beim Einsatz des Facebook-Tools und für die
      datenschutzrechtlich sichere Implementierung des Tools auf unserer Website verantwortlich. Für
      die Datensicherheit der Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte (z. B.
      Auskunftsersuchen) hinsichtlich der bei Facebook verarbeiteten Daten können Sie direkt bei
      Facebook geltend machen. Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
      verpflichtet, diese an Facebook weiterzuleiten.<br><br>
      Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission
      gestützt. <br>Details finden Sie hier: <br>https://www.facebook.com/legal/
      EU_data_transfer_addendum, https://de-de.facebook.com/help/566994660333381 und https://
      www.facebook.com/policy.php.<br><br>
      Hinweise zur Nutzung von WhatsApp:<br>
      Uns ist bewusst, dass die Nutzung von WhatsApp insbesondere aus datenschutzrechtlicher
      Perspektive nicht immer unproblematisch ist.<br>
      Wir möchten Ihnen jedoch, soweit Sie sich dazu entschieden haben WhatsApp als Anwendung zu
      nutzen und uns hiermit zu kontaktieren, die Möglichkeit geben, dies auch zu tun.<br>
      Wir haben daher zahlreiche Maßnahmen ergriffen, um das Risiko für Ihre personenbezogenen
      Daten zu minimieren. Hierbei haben wir uns an den Empfehlungen der Datenschutzbeauftragten
      der einzelnen Bundesländer orientiert.<br><br>
      Zu unseren Maßnahmen zählen insbesondere:<br><br>

      - der Einsatz aktueller Software-Versionen, um eine Verschlüsselung der
        Kommunikationsinhalte zu gewährleisten<br>
      - der Einsatz einer tragfähigen Containerlösung über unser Ticketsystem<br>
      - die Nutzung eines „one-record-Adressbuchs“ mit ausschließlich der Telefonnummer des
        Diensteanbieters, eines Telefonbuchs mit ausschließlich WhatsApp-Kontakten oder eine
      - Sperre des Adressbuchzugriffs durch WhatsApp<br>
      - keine Cloud-Backups<br>
      - keine Nutzung von Mobiltelefonen<br>
      - eine ausreichende Absicherung der Endgeräte (Zugriffssperre, Verschlüsselung)<br><br>

      Insbesondere nutzten wir für die Kommunikation mit Ihnen keine WhatsApp Anwendungen. Die
      Kommunikation läuft ausschließlich über das von unserem Dienstleister zur Verfügung gestellte
      Ticketsystem.<br>
      Mithin übertragen wir selbst keine, unsere Kunden betreffenden Daten, insbesondere Ihre
      Kontaktdaten, inklusive ihrer Telefonnummer, an WhatsApp. Die Kommunikation über WhatsApp ist
      jederzeit Ende-zu-Ende verschlüsselt solange Sie mit uns kommunizieren.<br>
      Wir weisen Sie allerdings darauf hin, dass die Übertragung von Informationen an nicht sichere
      Drittstaaten, wie hier die USA, zur datenschutzrechtlichen Risiken führen können.<br>
      Hierzu zählen vorliegend insbesondere:<br><br>
      - der Tatsache, dass WhatsApp als Anbieter außerhalb des Geltungsbereichs europäischer
      Datenschutzvorschriften fungiert,<br>
      - der Vertraulichkeit der Kommunikation sowie<br>
      - der regelmäßigen Übertragung von Kontaktdaten aus dem Adressbuch Ihres Smartphones.<br><br>
      Sollten Ihnen diese Risiken zu hoch sein, haben Sie jederzeit die Möglichkeit, uns über andere
      Kommunikationswege zu erreichen.</p>

    <h2>Betroffenenrechte und Speicherdauer</h2><br>
    <p>Dauer der Speicherung<br>
      Die Daten werden unter Berücksichtigung gesetzlicher Aufbewahrungsfristen gespeichert und
      dann nach Fristablauf gelöscht, sofern Sie der weitergehenden Verarbeitung und Nutzung nicht
      zugestimmt haben.<br>
      Rechte der betroffenen Person<br>
      Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte nach Art. 15 bis 20
      DSGVO zu: Recht auf Auskunft, auf Berichtigung, auf Löschung, auf Einschränkung der
      Verarbeitung, auf Datenübertragbarkeit.<br>
      Außerdem steht Ihnen nach Art. 21 Abs. 1 DSGVO ein Widerspruchsrecht gegen die Verarbeitungen
      zu, die auf Art. 6 Abs. 1 f DSGVO beruhen, sowie gegen die Verarbeitung zum Zwecke von
      Direktwerbung.<br>
      Beschwerderecht bei der Aufsichtsbehörde<br>
      Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn
      Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig
      erfolgt.<br><br>
      Widerspruchsrecht<br>
      Beruhen die hier aufgeführten personenbezogenen Datenverarbeitungen auf Grundlage unseres
      berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO, haben Sie das Recht aus Gründen, die sich
      aus Ihrer besonderen Situation ergeben, jederzeit diesen Verarbeitungen mit Wirkung für die
      Zukunft zu widersprechen.<br>
      Nach erfolgtem Widerspruch wird die Verarbeitung der betroffenen Daten beendet, es sei denn, wir
      können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihren Interessen,
      Rechten und Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung
      oder Verteidigung von Rechtsansprüchen dient.

    </p>



  </div>
</template>

<script>
export default {
  name: "Datenschutz"
}
</script>

<style scoped>
.datenschutz-container {
  font-family: Arial, sans-serif;
  max-width: 80%;
  margin: auto;
  padding: 20px;
}
</style>