<template>
  <div class="content">
    <div class="footer">
      <ul class="footer-links">

        <li class="cursor-pointer" @click="goToAGB">AGB</li>
        <li class="cursor-pointer" @click="goToDatenschutz">Datenschutz</li>
        <li class="cursor-pointer" @click="goToImpressum">Impressum</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {

    goToAGB() {
      this.$router.push('/agb');
    },
    goToDatenschutz() {
      this.$router.push('/datenschutz');
    },
    goToImpressum() {
      this.$router.push('/impressum');
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #f5f5f5;
  padding: 20px 0;
  width: 100%;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.footer-links li {
  display: inline;
  margin: 0 10px;
}

.footer-links li:hover {
  text-decoration: underline;
}


</style>
